var pug = require("!../../../node_modules/pug-runtime/index.js");

function template(locals) {var pug_html = "", pug_mixins = {}, pug_interp;;
    var locals_for_with = (locals || {});
    
    (function (config, css) {
      pug_mixins["link"] = pug_interp = function(item){
var block = (this && this.block), attributes = (this && this.attributes) || {};
if (item.heading) {
pug_html = pug_html + "\u003Cstrong\u003E" + (pug.escape(null == (pug_interp = item.heading) ? "" : pug_interp)) + "\u003C\u002Fstrong\u003E";
}
pug_html = pug_html + "\u003Cp\u003E";
if (item.href) {
pug_html = pug_html + "\u003Ca" + (pug.attr("class", pug.classes([item.class], [true]), false, true)+pug.attr("href", item.href, true, true)+pug.attr("title", item.title, true, true)) + "\u003E" + (pug.escape(null == (pug_interp = item.text || item.href) ? "" : pug_interp)) + "\u003C\u002Fa\u003E";
}
else {
pug_html = pug_html + (pug.escape(null == (pug_interp = item.text) ? "" : pug_interp));
}
pug_html = pug_html + "\u003C\u002Fp\u003E";
};
pug_html = pug_html + "\u003Cfooter" + (pug.attr("class", pug.classes([css.footer], [true]), false, true)) + "\u003E";
if (config.gray && config.gray.text) {
pug_html = pug_html + "\u003Cdiv" + (pug.attr("class", pug.classes([css.gray], [true]), false, true)) + "\u003E\u003Cdiv" + (pug.attr("class", pug.classes([css.container], [true]), false, true)) + "\u003E" + (null == (pug_interp = config.gray.text) ? "" : pug_interp) + "\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E";
}
pug_html = pug_html + "\u003Cdiv" + (pug.attr("class", pug.classes([css.container], [true]), false, true)) + "\u003E\u003Cdiv\u003E\u003Cp\u003E\u003Cimg" + (pug.attr("src", require("../../svg/bgsu.svg"), true, true)+" alt=\"BGSU\" width=\"192\" height=\"58\"") + "\u003E\u003C\u002Fp\u003E\u003Cdiv" + (pug.attr("class", pug.classes([css.address], [true]), false, true)) + "\u003E\u003Cdiv\u003EBowling Green State University\u003C\u002Fdiv\u003E\u003Cdiv\u003EBowling Green, Ohio\u003C\u002Fdiv\u003E\u003Cdiv\u003E43403‑0001\u003C\u002Fdiv\u003E\u003Cdiv\u003E419‑372‑2531\u003C\u002Fdiv\u003E";
if (config.help) {
pug_mixins["link"](config.help);
}
pug_html = pug_html + "\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003Cdiv" + (pug.attr("class", pug.classes([css.small], [true]), false, true)) + "\u003E";
pug_mixins["link"]({
                heading: "Accessibility",
                href: "https://www.bgsu.edu/accessibility/",
                text: "Report an Issue",
                class: css['link-accessibility']
            });
pug_html = pug_html + "\u003Cstrong\u003EEvents\u003C\u002Fstrong\u003E\u003Cp\u003ETo individuals with disabilities, please indicate if you need\nspecial services, assistance or appropriate modifications to\nfully participate in events by contacting\n\u003Ca href=\"https:\u002F\u002Fwww.bgsu.edu\u002Faccessibility\u002F\"\u003EAccessibility\u003C\u002Fa\u003E\nServices, \u003Ca href=\"mailto:access@bgsu.edu\"\u003Eaccess@bgsu.edu\u003C\u002Fa\u003E,\n419‑372‑8495. Please notify us prior to the event.\u003C\u002Fp\u003E\u003Cstrong\u003ETitle IX Administrative Contact Information\u003C\u002Fstrong\u003E\u003Cp\u003EComplaints or notice of alleged policy violations, or inquiries\nabout or concerns regarding Title IX (sexual harassment) and\nprocedures, may be made internally to:\n\u003Ca href=\"mailto:titleix@bgsu.edu\"\u003ELakeshia Dowlen\u003C\u002Fa\u003E, Title IX\nCoordinator, Office of Title IX, 207 Hayes Hall, Bowling Green,\nOhio 43402, 419‑372‑8476. Inquiries may be made externally to:\n\u003Ca href=\"http:\u002F\u002Fwww.ed.gov\u002Focr\"\u003EOffice for Civil Rights\n(OCR)\u003C\u002Fa\u003E, U.S. Department of Education, 400 Maryland Ave SW,\nWashington, D.C. 20202‑1100. Customer Service Hotline:\n800‑421‑3481, \u003Ca href=\"mailto:OCR@ed.gov\"\u003EOCR@ed.gov\u003C\u002Fa\u003E.\u003C\u002Fp\u003E";
pug_mixins["link"]({
                href: "https://www.bgsu.edu" +
                    "/equity-diversity-and-inclusion" +
                    "/title-ix" +
                    "/title-ix-administrative-contact-information.html",
                text: "Title IX Deputy Coordinators",
                class: css['link-coordinators']
            });
pug_html = pug_html + "\u003Cstrong\u003ESee It. Hear It. Report It.\u003C\u002Fstrong\u003E\u003Cp\u003EMisconduct occurring on or off campus, Sexual assault or sexual\nharassment or, a behavior of concern you would like to bring to\nthe attention of the Office of the Dean of Students.\u003C\u002Fp\u003E";
pug_mixins["link"]({
                href: "https://cm.maxient.com/reportingform.php" +
                    "?BowlingGreenStateUniv",
                text: "Report an Incident",
                class: css['link-incident']
            });
pug_html = pug_html + "\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Ffooter\u003E";
    }.call(this, "config" in locals_for_with ?
        locals_for_with.config :
        typeof config !== 'undefined' ? config : undefined, "css" in locals_for_with ?
        locals_for_with.css :
        typeof css !== 'undefined' ? css : undefined));
    ;;return pug_html;};
module.exports = template;