// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "[aria-hidden=true]{display:none}.bgsu_toggle_URnC1 .toggle-control{background:transparent;border:0;font:inherit;line-height:1.5;margin:0;padding:0;pointer-events:none;text-align:left}.bgsu_toggle_URnC1 .toggle-control[aria-controls]{cursor:pointer;pointer-events:auto}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"toggle": "bgsu_toggle_URnC1"
};
export default ___CSS_LOADER_EXPORT___;
